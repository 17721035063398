import React, {useState} from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import MomentLocaleUtils from 'react-day-picker/moment'
import {isMobile} from 'react-device-detect'
import styled from 'styled-components'
import prevIcon from 'img/circle-arrow-left-solid'
import nextIcon from 'img/circle-arrow-right-solid'
import time from 'i18n/time'

const StyledDayPicker = styled(DayPicker)`
  .DayPicker-wrapper {
    background-color: white;
    border: 1px solid #eee;
    border-radius: 5px;
  }

  .DayPicker-Caption {
    background: var(--primary);
    border-radius: 3px;
    color: white;
    padding: 5px;

    > div {
      // font-weight: bold;
    }
  }

  .DayPicker-NavButton {
      top: 0.5em;
      right: 0.5em;
      width: 2em;
  }

  .DayPicker-NavBar {
    color: white;
  }

  .DayPicker-NavButton--prev {
    filter: invert(100%);
    background-image: url(${prevIcon});
  }

  .DayPicker-NavButton--next {
    filter: invert(100%);
    background-image: url(${nextIcon});
  }

  .DayPicker-Month {
    margin: 0em;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background-color: red;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: var(--primary);
  }

  .DayPicker-Weekday {
    color: var(--primary);
  }

  .DayPicker-Day {
    ${props => props.isMobile && `padding: 0.5em;`}
    ${props => !props.isMobile && `padding: 0.5em 0.85em;`}
    font-size: 1em;
    font-weight: bold;
    border-radius: 0%;
    border: 1px solid #e9ecef
  }

  .DayPicker-Caption > div {
    text-align: center;
  }

  .DayPicker-Day--past {
    background: #f1f1f1;
    color: #cfcfcf;
  }

  .DayPicker-Day--today {
    color: red;
  }
`;

const CalendarPicker = ({initialDay, daysAvailable, onDaySelected, onMonthChange, locale}) => {
  const firsSelectedDay = new Date(initialDay) || new Date()
  const [selectedDay, setSelectedDay] = useState(firsSelectedDay)

  const isDayDisabled = (day) => {
    const localTime = time.tz(day, "Europe/Madrid").format('YYYY-MM-DD')

    if (!daysAvailable) {
      return false
    }

    const isDayMatch = daysAvailable.some(enabledDay => {
      return localTime == enabledDay
    })

    return !isDayMatch
  }

  const handleDayClick = (day, {disabled}) => {
    if (disabled) {
      return
    }

    setSelectedDay(day)
    onDaySelected(day)
  }

  const handleMonthChange = (day) => {
    onMonthChange(day)
  }

  const modifiers = {
    today: new Date(),
    past: { from: new Date(1900, 0, 1), to: time().add(-1, 'days').toDate() }
  };

  return (
    <StyledDayPicker
      selectedDays={selectedDay}
      onDayClick={handleDayClick}
      onMonthChange={handleMonthChange}
      disabledDays={[
        isDayDisabled,
        {before: firsSelectedDay}
      ]}
      fromMonth={firsSelectedDay}
      initialMonth={firsSelectedDay}
      showOutsideDays
      localeUtils={MomentLocaleUtils}
      locale={locale}
      firstDayOfWeek={1}
      isMobile={isMobile}
      modifiers={modifiers}
    />
  )
}

export default CalendarPicker

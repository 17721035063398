import React, {useState} from 'react'
import i18n from 'i18n/config'
import spainFlag from 'img/flags/Spain'
import ukFlag from 'img/flags/UnitedKingdom'
import franceFlag from 'img/flags/France'
import mexicoFlag from 'img/flags/Mexico'
import chinaFlag from 'img/flags/China'
import styled from 'styled-components'
import { CircleFlag as BaseFlag } from 'react-circle-flags'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import classNames from 'classnames'
// import PropTypes from 'prop-types';

const FlagLink = styled.a`
  img {
    ${({active}) => !active &&`
      filter: gray; /* IE5+ */
      -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
      -webkit-transition: all .2s ease-in-out;
    `}


    &:hover {
      filter: none;
        -webkit-filter: grayscale(0);
        -webkit-transform: scale(1.01);
      }
    }
  }
`

const Flag = ({code}) => {
  if (code == 'en') {
    return <img width="26" height="26" src={ukFlag} alt="United Kingdom English language flag"/>
  } else if (code == 'es') {
    return <img width="26" height="26" src={spainFlag} alt="Español language flag"/>
  } else if (code == 'es_mx') {
    return <img width="26" height="26" src={mexicoFlag} alt="Español language flag"/>
  } else if (code == 'fr') {
    return <img width="26" height="26" src={franceFlag} alt="Français language flag "/>
  } else if (code == 'zh') {
    return <img width="26" height="26" src={chinaFlag} alt="China language flag "/>
  }
}

const FlagIcon = styled(BaseFlag)`
  img {
    ${({active}) => !active &&`
      filter: gray; /* IE5+ */
      -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
      -webkit-transition: all .2s ease-in-out;
    `}


    &:hover {
      filter: none;
        -webkit-filter: grayscale(0);
        -webkit-transform: scale(1.01);
      }
    }
  }
`

const FlagWrapper = styled.div`
    // cursor: pointer;
    // border: 2px solid white;
    // background-color: #FFFFFF;
    // height: 100%;
    // border-radius:50%;
    // width: 100%;
`

const LanguageSwitcher = (props) => {
  const {en = true, es = true, es_mx = true, fr = false, zh = false, urls} = props

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const host = window.location.host
  const isMexicoDomain = host.includes('.mx')

  const redirectToLanguage = (code) => {
    return window.location.href = urls[code]
  }

  const flagCode = (code) => {
    if (code == 'en') {
      return 'gb'
    } else if (code == 'es_mx') {
      return 'mx'
    } else if (code == 'zh') {
      return 'ch'
    } else {
      return code
    }
  }

  return (
    <React.Fragment>
      <Dropdown direction={'down'} toggle={function noRefCheck(){}}>
        <DropdownToggle
          data-toggle="dropdown"
          tag="div"
          className="p-2"
          style={{cursor: 'pointer'}}
        >
          <FlagIcon countryCode={flagCode(i18n.language)} height="23" className="mr-1"/>
          <small><a className='language-code d-inline p-0 nav-link text-white text-uppercase font-weight-bold'>{i18n.language}</a></small>
        </DropdownToggle>
        <DropdownMenu style={{width: '100%'}}>
          {es &&
            <DropdownItem onClick={() => redirectToLanguage('es')}>
              <FlagIcon countryCode="es" height="23" className="mr-1"/> Español
            </DropdownItem>
          }

          {es_mx && isMexicoDomain &&
            <React.Fragment>
              <DropdownItem onClick={() => redirectToLanguage('es_mx')}>
                <FlagIcon countryCode="mx" height="23" className="m-1"/> Español
              </DropdownItem>
            </React.Fragment>
          }

          {en &&
            <React.Fragment>
              <DropdownItem divider />
              <DropdownItem onClick={() => redirectToLanguage('en')}>
                <FlagIcon countryCode="gb" height="23" className="m-1"/> English
              </DropdownItem>
            </React.Fragment>
          }
          {fr &&
            <React.Fragment>
              <DropdownItem divider />
              <DropdownItem onClick={() => redirectToLanguage('fr')}>
                <FlagIcon countryCode="fr" height="23" className="m-1"/> Française
              </DropdownItem>
            </React.Fragment>
          }

          {zh &&
            <React.Fragment>
              <DropdownItem divider />
              <DropdownItem onClick={() => redirectToLanguage('zh')}>
                <FlagIcon countryCode="cn" height="23" className="m-1"/> 汉语
              </DropdownItem>
            </React.Fragment>
          }
        </DropdownMenu>
      </Dropdown>

    </React.Fragment>
  )
}

export default LanguageSwitcher

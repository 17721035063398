import React from 'react'
import {ModulePanel, ModuleHeader, ModuleBody} from 'containers/purchase/modules/module_panel'
import {ListGroup, ListGroupItem} from 'reactstrap'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import time from 'i18n/time'

const PaymentModule = ({...props}) => {
  const { t, i18n } = useTranslation()
  const [field, meta] = useField(props)
  const {setFieldValue} = useFormikContext()
  const {payment_methods} = props.user
  const {expires_at} = props.cart

  return (
    <ModulePanel>
      <ModuleHeader status="active" showIcon={false}>
        <span>
          <i className="fa fa-credit-card mr-2" aria-hidden="true"></i>
          {i18n.t('payment_module.payment_title')}
        </span>
      </ModuleHeader>
      <ModuleBody status="active" className={{}}>
        <ListGroup>
          {payment_methods.map(({code, title, label, label_es, label_en}) =>
            <ListGroupItem
              key={code}
              color={field.value == code ? 'warning' : ''}
              onClick={() => setFieldValue(field.name, code)}
              className="flex-column align-items-start rounded-0"
              style={{cursor: 'pointer'}}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                  <i className="fa fa-arrow-right mr-2" style={{visibility: field.value != code ? 'hidden' : ''}}></i>
                  {i18n.language == 'es' ? label_es : label_en}
                  {code == 'paygold' &&
                    <div className="pl-4">
                      <small><i>{i18n.t('payment_module.paygold_expiration_alert', {expiration: time(expires_at).format('LLL')})}</i></small>
                    </div>
                  }
                </h5>
                {(code == 'generic_credit_card' || code == 'paygold') &&
                  <p className="p-0 m-0">
                    <i className="fa fa-cc-visa mr-2"></i>
                    <i className="fa fa-cc-mastercard mr-2"></i>
                    <i className="fa fa-cc-amex"></i>
                  </p>
                }
              </div>
            </ListGroupItem>
          )}
        </ListGroup>
      </ModuleBody>
    </ModulePanel>
  )
}

export default PaymentModule
